import React from "react";
import "antd/dist/antd.css";
import { Menu } from "antd";
import { Link } from "react-router-dom";

const { SubMenu } = Menu;

class MainMenu extends React.Component {
  state = {
    current: "all",
  };

  handleClick = (e) => {
    // console.log(filterDemo(DemoObject, 'Shopify'))
    this.setState({
      current: e.key,
    });
  };

  render() {
    return (
      <div id="main-menu">
        <Menu
          onClick={this.handleClick}
          selectedKeys={[this.state.current]}
          mode="horizontal"
          defaultSelectedKeys={"all"}
        >
          <Menu.Item key="all">
            <Link to="/">All</Link>
          </Menu.Item>
          <SubMenu title="Platforms">
            <Menu.ItemGroup title="Name">
              <Menu.Item key="shopify">
                <Link to="/p/shopify">Shopify</Link>
              </Menu.Item>
              <Menu.Item key="magento"><Link to="/p/magento">Magento</Link></Menu.Item>
            </Menu.ItemGroup>
          </SubMenu>
          <Menu.Item key="help">
            <Link to="/help">Help</Link>
          </Menu.Item>
        </Menu>
      </div>
    );
  }
}

export default MainMenu;
