import React from "react";
import { Layout, Menu } from "antd";
import Demos from "./components/DemoList";
import Help from "./pages/Help/index.js";

import PlatformSort from "./components/PlatformSort";
// import "./App.css";
import "./App.less";
import 'antd-mobile/dist/antd-mobile.css';  // or 'antd-mobile/dist/antd-mobile.less'


import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import MainMenu from "./components/Menu";
import ReactGA from "react-ga";

const { Header, Content, Footer } = Layout;

let currentYear = new Date().getFullYear();


function App(props) {
  ReactGA.initialize("UA-63905846-9");
  ReactGA.set({ dimension1: "demo.threekit.com" });
  ReactGA.pageview("Demo Homepage");

  return (
    <div className="App">
      <Layout className="layout">
        <a href="/"><Header id="header"></Header></a>
        <Router>
          <MainMenu />

          <Content style={{ padding: "1em" }}>
            <Switch>
              <Route path="/p/:platform">
                <PlatformSort />
              </Route>

              <Route exact path="/" key={'home'}>
                <Demos />
              </Route>

              <Route exact path="/help" key={'help'}>
                <Help />
              </Route>

              <Route component={Demos} key={'selected'}/>
            </Switch>
          </Content>
          <Footer style={{ textAlign: "center" }}>
            Threekit ©{currentYear}
          </Footer>
        </Router>
      </Layout>
    </div>
  );
}

export default App;
