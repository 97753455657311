import React from "react";
import { Card, Button, Menu, PageHeader, Layout, Typography, Table } from "antd";
import "./style.css";
import {
  SaveOutlined,
  SaveFilled,
  SearchOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";

const { Content } = Layout;
const { Title, Text } = Typography;

const { SubMenu } = Menu;

const columns = [
  {
    title: "Icon",
    dataIndex: "icon",
  },
  {
    title: "Description",
    dataIndex: "description",
  },
];
const data = [
  {
    key: "1",
    icon: <SearchOutlined />,
    description: "Launch demo in new window",
  },
  {
    key: "2",
    icon: <SaveOutlined />,
    description: "Add demo to a curated list for sharing",
  },
  {
    key: "3",
    icon: <SaveFilled />,
    description: "Demo is in a list for sharing",
  },
  {
    key: "4",
    icon: <Button>Share</Button>,
    description: "Navigate to curated list. URL is ready to share",
  },
];

function Help() {
  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => (window.location = "/")}
        title="Help"
        subTitle="How to launch & share demos"
      />
      <Content className="layout">
        <div className="page-content">
          <Title level={3}>Help</Title>
          <Title level={4}>Icon Guide</Title>
          <div className="table-container">
            <Table  columns={columns} dataSource={data} pagination={false}/>
          </div>
        </div>
      </Content>
    </div>
  );
}

export default Help;
