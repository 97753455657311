import React from "react";
import "./Demo.css";
import "antd/dist/antd.css";
import { Icon, Card, List, Menu, Button, Tooltip } from "antd";
import { isMobile } from "react-device-detect";
import {
  SaveOutlined,
  SaveFilled,
  SearchOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { Toast } from "antd-mobile";

// import Menu from '../Menu'
import { DemoObject } from "../../config/Configs";

import { CopyToClipboard } from "react-copy-to-clipboard";

const { SubMenu } = Menu;

let newArr = [];

let copied = false;

function CopyText(props) {
  if (isMobile) {
    return (
      <div>
        <CopyToClipboard
          text={
            new URL(
              window.location.href + `${JSON.stringify(props.state.selected)}`
            )
          }
        >
          <Button onClick={props.showToast}>Share Demos</Button>
        </CopyToClipboard>
      </div>
    );
  }
  return (
    <div>
      <Tooltip title={"Shareable link to clipboard!"} trigger={"focus"}>
        <CopyToClipboard
          text={
            new URL(
              window.location.href + `${JSON.stringify(props.state.selected)}`
            )
          }
        >
          <Button>Share Demos</Button>
        </CopyToClipboard>
      </Tooltip>
    </div>
  );;
}


class Products extends React.Component {
  state = {
    current: DemoObject,
    selected: [],
    newData: [],
    isList: false,
  };

  parseUrl = (arr, i) => {
    arr.map((currElement, index) => {
      if (index == i) {
        console.log(currElement);
        newArr.push(currElement);
        this.setState({ newData: newArr });
        return currElement;
      }
    });
  };

  getSharedItems = () => {
    if (this.props.location) {
      this.setState({ isList: true });
      console.log(JSON.parse(this.props.location.pathname.substr(1)));
      let arr = JSON.parse(this.props.location.pathname.substr(1));
      arr.map((a) => {
        // Gets demos based on array in URL
        this.parseUrl(DemoObject, a);
      });
    }
  };

  componentDidMount() {
    this.getSharedItems();
    console.log(isMobile);
  }

  handleClick = (e) => {
    this.setState({
      current: e.key,
    });
  };

  showToast = () => {
    Toast.info("Shareable URL copied to clipboard", 2);
  };

  selectDemo = (i) => {
    var index = i;
    var array = this.state.selected;
    if (array.includes(index)) {
      let newIndex = array.indexOf(index);
      array.splice(newIndex, 1);
    } else {
      array.push(index);
    }
    this.setState({ selected: array });
  };

  // copyLink = (copyText) => {
  //   <CopyToClipboard text={copyText} />
  // }

 



  render() {
    return (
      <div id="product-content">
        {this.state.selected.length > 0 ? (<CopyText showToast={this.showToast} state={this.state}/>
        ) : (
          <p></p>
        )}

        <List
          grid={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5, xxl: 5 }}
          dataSource={
            this.state.isList ? this.state.newData : this.state.current
          }
          pagination={true}
          //   loading={true}
          renderItem={(item, i) => (
            <List.Item>
              <Card
                cover={
                  <img
                    style={{
                      maxHeight: 200,
                      maxWidth: 200,
                      width: isMobile ? "auto" : "auto",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    src={item.imageURL}
                  ></img>
                }
                actions={
                  !this.state.isList
                    ? [
                        <a href={item.link} target="_blank">
                          <SearchOutlined
                            type={"primary"}
                            href={item.link}
                            target="_blank"
                          ></SearchOutlined>
                        </a>,
                        this.state.selected.includes(i) ? (
                          <SaveFilled onClick={(e) => this.selectDemo(i)} />
                        ) : (
                          <SaveOutlined onClick={(e) => this.selectDemo(i)} />
                        ),
                      ]
                    : [
                        <Button
                          type={"primary"}
                          href={item.link}
                          target="_blank"
                        >
                          Go
                        </Button>,
                      ]
                }
              >
                <Card.Meta title={item.title} description={item.subtitle} />
              </Card>
            </List.Item>
          )}
        ></List>
      </div>
    );
  }
}

export default Products;
