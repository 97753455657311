import React from "react";
import "./style.css";
import "antd/dist/antd.css";
import { Card, List, Menu, Button, PageHeader } from "antd";
import { isMobile } from "react-device-detect";
import { DemoObject } from "../../config/Configs";
import { useParams } from "react-router-dom";
import filterDemo from "../../helpers/filterDemo";

const { SubMenu } = Menu;

function SortedDemo() {
  let { platform } = useParams();
  console.log(platform);
  console.log(DemoObject[platform]);

  return (
    <div>
      <div>
      {platform == "shopify" || "magento" ? (
        <PageHeader
          className="site-page-header"
          onBack={() => (window.location = "/")}
          title={
            platform.charAt(0).toUpperCase() +
            platform.toLowerCase().slice(1) +
            " Demos"
          }
          // subTitle="Sorted by platform"
        />
      ) : (
        <h1>Demos</h1>
      )}
      </div>


      <List
        grid={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5, xxl: 5 }}
        dataSource={filterDemo(DemoObject, platform)}
        pagination={true}
        renderItem={(item) => (
          <List.Item>
            <Card
              cover={
                <img
                  style={{
                    maxHeight: 200,
                    maxWidth: 200,
                    width: isMobile ? "auto" : "auto",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  src={item.imageURL}
                ></img>
              }
              actions={[
                <Button href={item.link} target="_blank" type={"primary"}>
                  Go
                </Button>,
              ]}
            >
              <Card.Meta title={item.title} description={item.subtitle} />
            </Card>
          </List.Item>
        )}
      ></List>
    </div>
  );
}

export default SortedDemo;
