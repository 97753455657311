const DemoObject = [
  {
    imageURL:
      "https://bath.demos.threekit.com/pub/media/logo/stores/1/Logo.png",
    features: ["3D", "AR", "VP"],
    platform: "magento",
    title: "Nile Kitchen & Batch",
    subtitle: "Luxury bath & kitchen",
    link: "https://bath.demos.threekit.com",
  },
  {
    imageURL:
      "https://solutions-engineering.s3.amazonaws.com/demo-homepage/images/thumb/luma-logo.png",
    features: ["3D", "AR", "VP"],
    platform: "magento",
    title: "Luma Furniture",
    subtitle: "Furniture & Home Goods",
    link: "https://furniture.demos.threekit.com",
  },
  {
    imageURL:
      "https://solutions-engineering.s3.amazonaws.com/demo-homepage/images/thumb/icarus-logo.png",
    features: ["3D", "AR", "VP"],
    platform: "shopify",
    title: "Icarus Luggage",
    subtitle: "Luggage x Shopify",
    link: "https://luggage.threekit.com",
  },
  {
    imageURL:
      "https://images-na.ssl-images-amazon.com/images/I/61CVcwZ4SrL._SL1500_.jpg",
    features: ["3D", "AR"],
    platform: "NA",
    title: "Quickdemo",
    subtitle: "Fast & Shareable demos",
    link: "https://quickdemo.threekit.com",
  },
  {
    imageURL:
      "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/rolled-sleeve/Black-Surfside-roll.jpg",
    features: ["3D", "VP"],
    platform: "NA",
    title: "Fashion",
    subtitle: "Fast & Shareable demos",
    link: "https://fashion.threekit.com",
  },
  // {
  //   imageURL:
  //     "https://solutions-engineering.s3.amazonaws.com/quickdemo/machinery/T590.png",
  //   features: ["3D", "AR"],
  //   platform: "NA",
  //   title: "Heavy Machinery",
  //   subtitle: "Configrable Skid-Steer",
  //   link: "https://machinery.demo.threekit.com",
  // },
];
export { DemoObject };
